<template lang="pug">
  .full-width.settings-view.lazy(data-lazy-function="loaded", :class="item.class")
    .container.is-flex
      .settings-view__setting-type
        .sub-menu
          .sub-menu__title {{ $t('layout.mainMenu.jewelryByType') }}
          .sub-menu__items-wrapper
            .sub-menu__item(v-for="menuItem in leftMenus", :key="menuItem.id", :class="{ more: !menuItem.img }")
              v-link(:to="menuItem.to", v-if="menuItem.img")
                v-image.sub-menu__item--img(:src="menuItem.img", :alt="menuItem.text")
                .sub-menu__item--name {{ menuItem.text }}
              v-link.sub-menu__item--more.is-flex(:to="menuItem.to", v-else)
                .sub-menu__item--name(v-html="menuItem.text")
      .settings-view__dyo
        v-link(:to="{ name: 'custom-design-gemstone-rings-jewelry' }")
          v-image.sub-menu__item--img.is-hidden-touch(src="mainMenu/settings/dyo-jewelry@2x.jpg", alt="Design your own jewelry")
          v-image.sub-menu__item--img.is-hidden-desktop(src="mainMenu/settings/dyo-jewelry-mobile@2x.jpg", alt="Design your own jewelry")
</template>

<script>
export default {
  name: 'JewelryComponent',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    leftMenus() {
      return this.item.items
    }
  }
}
</script>

<style lang="sass" scoped>
.full-width
  border-top: 1px solid $border-color
  width: 100%
.settings-view
  font-size: 14px
  background-color: #fff
  padding: 40px 35px
  @include box-shadow(0 4px 5px -3px, rgba(0,0,0,0.2))
  +mobile
    box-shadow: unset
    flex-direction: column
    padding: unset
  .container
    +touch
      flex-direction: column
  &__setting-type,
  &__dyo
    width: 50%
    +touch
      padding: 20px
      width: 100%
  &__setting-type
    +touch
      border-bottom: 12px solid $light-primary-color
  .sub-menu
    width: 100%
    &__title
      font-size: 16px
      font-family: $lora
      font-style: italic
    &__items-wrapper
      margin-top: 24px
      //margin-top: -16px
      font-size: 14px
      display: grid
      grid-template-columns: repeat(5, 1fr)
      column-gap: 34px
      row-gap: 40px
      +touch
        column-gap: 18px
        row-gap: 18px
        margin-top: 16px
        grid-template-columns: repeat(2, 1fr)
    &__item--img
      background-color: $secondary-bg-color
      //border: 10px solid $secondary-bg-color
      //aspect-ratio: 1 / 1
      align-items: center
    &__item--name
      margin-top: 10px
    &__item--more
      justify-content: center
      align-items: center
      border: 1px solid $secondary-bg-color
      max-height: 80px
      height: 100%
      font-weight: bold
    &__item
      text-align: center
      //width: 33%
      //margin-top: 40px
  &__dyo
    padding-left: 34px
    +desktop
      margin-top: 47px
    .sub-menu__item--img
      border: unset !important
    +touch
      padding-left: 20px
</style>
